"use client";

import { Provider } from "react-redux"
import store from "./store";
import { IntlProvider } from 'react-intl';
import { getCurrentLocale } from "../util/localeHelper";
interface PropTypes {
  children: React.ReactElement
}
const Providers: React.FC<PropTypes> = ({ children }) => {
  let localeHelper = getCurrentLocale();

  return (
    <Provider store={store}>
      <IntlProvider locale={localeHelper.locale} messages={localeHelper.message}>
        <div dir={localeHelper.direction}>{children}</div>
      </IntlProvider>

    </Provider>
  )
}
export default Providers;
